<template>
    <!-- 手写签名组件 -->
    <div class="page sign-page">
        <!-- 笔画颜色 -->
      <div class="yansexz">
        <div class="waibk" id="heise" style="border: 0.8px solid #000000;" @click="bainse('#000')"><div class="heised" style="background: #000;"></div></div>
        <div class="waibk" id="hongse" style="margin-top: 26px;" @click="bainse('#e93325')"><div class="heised" style="background: #e93325;"></div></div>
        <div class="waibk" id="lanse" style="margin-top: 22px;" @click="bainse('#1872ed')"><div class="heised" style="background: #1872ed;"></div></div>
      </div>
      <!-- 一键落章 -->
      <div class="yijianluozhang">
        <p class="luozhang">一键落章</p>
        <van-switch style="transform: rotate(90deg);margin-top: 38px;" v-model="checked" size="22px" />
      </div>
      <!-- 按钮 -->
      <div class="con-btn">
        <p class="staging-btn size14" :class="{ 'active':hasContent }" @click="clearArea">清除签名</p>
        <p class="submit-btn size14" @click="submitSign">确认签名</p>
      </div>
      <!-- 绘画版 -->
      <div class="content">
        <div class="sign-wrap" id="signWrap">
          <canvas id="myCanvas" width="100%" height="100%"></canvas>
        </div>
      </div>
      <div class="shouhuiqm">手绘签名</div>
      <van-icon name="arrow-up" class="back" @click="goBack" />
      <!-- 保存时翻转画板，保证保存的签名是横屏方向 -->
      <canvas id="userSign" style="position: absolute; bottom: 0;display: none;z-index: 0;"></canvas>
    </div>
</template>

<script>
import { FileUpladType } from '@/utils/constant'
import { mapGetters } from 'vuex'
import { Toast } from 'vant'
import uploadFile from '@/utils/cosUpload'
import { dataURLtoBlob, blobToFile, getSystemDate } from '@/utils/index'

export default {
  data() {
    return {
      image: "",
      mousePressed: false,
      c: "",
      ctx: "",
      lastX: 0,
      lastY: 0,
      baglist: '',
      checked: true,
      isSaveing: false,
      hasContent: false
    };
  },
  computed: {
    ...mapGetters({
      widgetSetting: 'contract/getWidgetSetting'
		})
  },
  mounted() {
    var heiselist = document.getElementById("heise"); 
    heiselist.onclick =() =>{
        heiselist.style.border = "0.8px solid #000";
        hongselist.style.border = "none";
        lanselist.style.border = "none";
    }
    var hongselist = document.getElementById("hongse");
    hongselist.onclick =() =>{
        hongselist.style.border = "0.8px solid #e93325";
        heiselist.style.border = "none";
        lanselist.style.border = "none";
    }
    var lanselist = document.getElementById("lanse");
    lanselist.onclick =() =>{
        lanselist.style.border = "0.8px solid #1872ed";
        hongselist.style.border = "none";
        heiselist.style.border = "none";
    }
    this.image = "";
    this.mousePressed = false;
    var lastX, lastY;
    this.ctx = document.getElementById("myCanvas").getContext("2d");
    this.c = document.getElementById("myCanvas");
    var signWrap = document.getElementById("signWrap");
    this.c.width = signWrap.clientWidth; // 设置宽度
    this.c.height = signWrap.clientHeight; // 设置高度
    // 监听touchstart事件，touchmove事件，touchend事件等事件
    this.InitThis();
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    bainse(item) {
        this.baglist = item
    },
    InitThis() {
      // 触摸屏
      var that = this;
      this.c.addEventListener(
        "touchstart",
        function (event) {
          if (event.targetTouches.length == 1) {
            event.preventDefault(); // 阻止浏览器默认事件，重要
            var touch = event.targetTouches[0];
            this.mousePressed = true;
            that.Draw(
              touch.pageX - this.offsetLeft,
              touch.pageY - this.offsetTop,
              false
            );
          }
        },
        false
      );
      this.c.addEventListener(
        "touchmove",
        function (event) {
          if (event.targetTouches.length == 1) {
            that.hasContent = true
            event.preventDefault(); // 阻止浏览器默认事件，重要
            var touch = event.targetTouches[0];
            if (this.mousePressed) {
              that.Draw(
                touch.pageX - this.offsetLeft,
                touch.pageY - this.offsetTop,
                true
              );
            }
          }
        },
        false
      );
      this.c.addEventListener(
        "touchend",
        function (event) {
          if (event.targetTouches.length == 1) {
            event.preventDefault(); // 阻止浏览器默认事件，防止手写的时候拖动屏幕，重要
            this.mousePressed = false;
          }
        },
        false
      );
      // 鼠标
      this.c.onmousedown = function (event) {
        this.mousePressed = true;
        that.Draw(
          event.pageX - this.offsetLeft,
          event.pageY - this.offsetTop,
          false
        );
      };
      this.c.onmousemove = function (event) {
        if (this.mousePressed) {
          that.hasContent = true
          that.Draw(
            event.pageX - this.offsetLeft,
            event.pageY - this.offsetTop,
            true
          );
        }
      };
      this.c.onmouseup = function (event) {
        this.mousePressed = false;
      };
    },
    Draw(x, y, isDown) {
      if (isDown) {
        this.ctx.beginPath();
        this.ctx.strokeStyle = this.baglist; // 颜色
        this.ctx.lineWidth = 3; // 线宽
        this.ctx.lineJoin = "round";
        this.ctx.lineMax = 10; // 设置画笔最大线宽
        this.ctx.lineMin = 3; // 设置画笔最小线宽
        this.ctx.linePressure = 1.2; // 设置画笔笔触压力
        this.ctx.smoothness = 30; // 设置画笔笔触大小变化的平滑度
        this.ctx.moveTo(this.lastX, this.lastY);
        this.ctx.lineTo(x, y);
        this.ctx.closePath();
        this.ctx.stroke();
      }
      this.lastX = x;
      this.lastY = y;
    },
    // 清空画板
    clearArea() {
      this.hasContent = false
      this.ctx.setTransform(1, 0, 0, 1, 0, 0);
      this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
    },
    // 验证canvas画布是否为空函数
    isCanvasBlank(canvas) {
      var blank = document.createElement("canvas"); // 系统获取一个空canvas对象
      blank.width = canvas.width;
      blank.height = canvas.height;
      return canvas.toDataURL() == blank.toDataURL(); // 比较值相等则为空
    },
    // 提交签名
    submitSign() {
      if(this.checkEmpty()) {
          this.copyContent()
      }
    },
    /**
     * 描述：非空验证
    **/
    checkEmpty() {
      var c = document.getElementById('myCanvas')
      if (this.isCanvasBlank(c)) {
        this.$toast.fail('请在签名区域签名后再次确认')
        return false
      } else {
        return true
      }
    },
    /**
     * 描述：将当前横屏的签名翻转为竖屏
    **/
    copyContent() {
      const image = this.c.toDataURL('image/png')
      const imgElm = new Image()
      imgElm.src = image
      imgElm.onload = async () => {
        const signCanvasElm = document.querySelector('#userSign')
        signCanvasElm.width = document.querySelector('#myCanvas').height
        signCanvasElm.height = document.querySelector('#myCanvas').width
        const copyCtx = signCanvasElm.getContext('2d')
        // 旋转90°
        // copyCtx.rotate((90 * Math.PI) / 180);
        // copyCtx.drawImage(imgElm, 0, -imgElm.height, imgElm.width, imgElm.height);
        // 旋转180°
        // copyCtx.rotate((180 * Math.PI) / 180);
        // copyCtx.drawImage(imgElm, -imgElm.width, -imgElm.height, imgElm.width, imgElm.height);
        // 旋转270度
        copyCtx.rotate((-90 * Math.PI) / 180)
        copyCtx.drawImage(imgElm, -imgElm.width, 0, imgElm.width, imgElm.height)
        const imageData = signCanvasElm.toDataURL('image/png')
        const blob = dataURLtoBlob(imageData)
        const file = blobToFile(blob, '个人签名.png')
        const ossUrl = await uploadFile(file, FileUpladType.CONTRACT)
        this.saveSign(ossUrl)
      }
    },
    async saveSign(url) {
      if(this.isSaveing) {
        return
      }
      this.isSaveing = true
      try {
        // const image = this.c.toDataURL("image/png")
        // const blob = dataURLtoBlob(image)
        // const file = blobToFile(blob, '个人签名.png')
        // const ossUrl = await uploadFile(file)
        const { data: { code } } = await this.$api.post('/user/app/addSignature', {
          address: url
        })
        if(code === 200) {
          Toast('保存成功')
        } else {
          Toast('保存失败') 
        }
        // 如果开启了一键落章，需要存储当前章的地址到组件的value中
        if(this.checked) {
          this.updateSignUrlToWidget(url)
        }
        // this.$router.go(-1)
        this.$router.replace({ name: 'ViewSigning', query: { id: this.$route.query.contractId, sign_id: this.$route.query.signId } })
      } finally {
        this.isSaveing = false
      }
    },
    updateSignUrlToWidget(url) {
      const widgetId = this.$route.query.id
      const initiator = this.$route.query.initiator
      const widgets = this.widgetSetting.map(item => {
        if(item.id === widgetId ) {
          item.value = url
          item.default = 1
        }
        // 落章后更新个人日期组件时间
        if(item.widget_type === 2 && item.label !== initiator) {
          item.value = getSystemDate()
        }
        return item
      })
      this.$store.dispatch('contract/setWidgetSetting', widgets)
    }
  }
}
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
}
.page {
  width: 100%;
  display: flex;
  background: #f6f6f6;
  .yijianluozhang {
    position: absolute;
    top: 264px;
    .luozhang {
        transform: rotate(90deg);
        font-size: 14px;
        font-family: Noto Sans SC, Noto Sans SC-400;
        font-weight: 400;
        color: #000000;
        margin-left: -5px;
    }
  }
  .yansexz {
    position: absolute;
    left: 15px;
    top: 26px;
    width: 20px;
    height: 98px;
    .waibk {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        .heised {
        width: 16px;
        height: 16px;
        // background: #e93325;
        border-radius: 50%;
        margin: 0 auto;
        margin-top: 2px;
    }
    }
  }
  .shouhuiqm {
    transform: rotate(90deg);
    position: absolute;
    right: 0;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-700;
    font-weight: 700;
    color: #1872ed;
    top: 327px;
  }
  .content {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 270px;
    height: 704px;
    background: #ffffff;
    border-radius: 10px;
    margin-left: 53px;
    // background: url(../../../assets/images/xiazaimo.png) no-repeat;
    // background-size: 100% 100%;
    // background-position: center center;
    .sign-wrap {
      width: 100%;
      height: 100%;
    }
  }
  .con-btn {
    // width: 100%;
    // align-content: center;
    // justify-content: space-between;
    opacity: 0.75;
    p {
    width: 80px;
    height: 30px;
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    transform: rotate(90deg);
    position: absolute;
    text-align: center;
    line-height: 30px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    }
    .staging-btn {
        color: #cccccc;
        background: #ffffff;
        border: 1px solid #b7b5b5;
        border-radius: 2px;
        top: 484px;
        left: -15px;
        &.active {
          color: #1872ed;
          border-color: #1872ed;
        }
    }
    .submit-btn {
        background: #ffffff;
        border: 1px solid #1872ed;
        border-radius: 2px;
        color: #1872ed;
        top: 584px;
        left: -15px;
    }
  }
  .back {
    position: absolute;
    top: 17px;
    right: 20px;
    font-size: 26px;
    color: #1872ed;
  }
}
</style>
  