/**
 * 描述：获取后台接口域名
**/

import Vue from 'vue'

// const isProd = process.env.NODE_ENV === 'production'

const isProd = false

export function getLiveApi() {
    if(!isProd) {
        return process.env.VUE_APP_API_ROOT
    }
    return Vue.prototype.SHANHU_LIVE_API
}

export function getOnlineApi() {
    if(!isProd) {
        return `${process.env.VUE_APP_API_ONLINE_SCHOOL}/onlineapi`
    }
    return `${Vue.prototype.SHANHU_ONLINE_API}/onlineapi`
}

export function getOnlineNotFixApi() {
    if(!isProd) {
        return process.env.VUE_APP_API_ONLINE_SCHOOL
    }
    return Vue.prototype.SHANHU_ONLINE_API
}
export function getTenghuiApi() {
    if(!isProd) {
        return process.env.VUE_APP_API_ONLINE
    }
    return Vue.prototype.SHANHU_ONLINE_API
}

export function getDataApi() {
    if(!isProd) {
        return process.env.VUE_APP_API_DATA
    }
    return `${Vue.prototype.SHANHU_DATA_API}`
}

export function getLiveHyApi() {
    if(!isProd) {
        return process.env.VUE_APP_API_SUBTITLES
    }
    return Vue.prototype.SHANHU_LIVE_HY_API
}

export function getScrmApi() {
    if(!isProd) {
        return process.env.VUE_APP_API_SCRM
    }
    return Vue.prototype.SHANHU_SCRM_API
}

export const getHost = () => {
    if(['localhost', '127.0.0.1'].includes(window.location.hostname) || !isProd) {
        return 'https://test-school.tenghuiketang.com'
    }
    return 'https://onlinehy.shkt.online'
}
